<template>
  <b-modal
    id="create-or-update-class-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">บันทึกการสอน</h3>
      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด:
        {{ $_formatDateMixin_formatShort(editData.updatedAt) }}</span
      >
    </template>

    <div class="d-flex flex-row align-items-baseline px-2">
      <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
      <h4 class="font-weight-bold ft-s-18">ข้อมูลห้องเรียน</h4>
    </div>

    <filter-master
      v-model="filterMaster"
      :m-headquarter-id="editData.mHeadquarterId"
      :m-division-id="editData.mDivisionId"
      :m-station-id="editData.mStationId"
      :m-school-id="editData.mSchoolId"
      :m-class-id="editData.mClassId"
      :disabled-input="{
        headQuarter: true,
        division: true,
        station: true,
        schoolClass: true,
      }"
      col-headquarter="4"
      col-division="4"
      col-station="4"
      col-school-class="2"
      default-label="-"
      :hidden-input="{ school: true }"
    >
      <template #beforeSchoolClass>
        <b-col cols="10" class="px-0">
          <!-- <div class="d-flex flex-row align-items-baseline px-2">
            <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
            <h4 class="font-weight-bold ft-s-18">ข้อมูลห้องเรียน</h4>
          </div> -->

          <filter-province
            v-model="filterProvince"
            :m-province-id="editData.mProvinceId || null"
            :m-amphur-id="editData.mAmphurId || null"
            :m-school-id="editData.mSchoolId || null"
            :disabled-input="{
              province: true,
              amphur: true,
              school: true,
            }"
            default-label="-"
          >
          </filter-province>
        </b-col>
      </template>

      <b-col cols="10" class="px-0">
        <teaching-dare-form
          v-model="formTeachingDare"
          :edit-data="editData"
          :isClass="true"
          :disabled="disabled || isSubmiting"
        ></teaching-dare-form>
      </b-col>
    </filter-master>

    <div class="d-flex flex-row align-items-baseline px-2 my-2">
      <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
      <h4 class="font-weight-bold ft-s-18">รายชื่อผู้อำนวยการโรงเรียน</h4>
    </div>
    <div
      v-if="formTeachingDare && formTeachingDare.director_dares[0]"
      class="d-flex flex-row flex-wrap"
    >
      <div class="col-lg-2 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="box5">คำนำหน้าชื่อ</label>
        <v-select
          v-model="formTeachingDare.director_dares[0].mPrefixId"
          :options="prefixes"
          :reduce="(prefixes) => prefixes.id"
          label="m_prefix_name"
          :loading="isFetchingPrefix"
          :disabled="disabled || isSubmiting"
        ></v-select>
        <!-- <input class="form-control custom__height" type="text" /> -->
      </div>
      <div class="col-lg-5 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="box5">ชื่อ</label>
        <input
          class="form-control custom__height"
          type="text"
          :disabled="disabled || isSubmiting"
          v-model="formTeachingDare.director_dares[0].first_name"
        />
      </div>
      <div class="col-lg-5 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="box5">สกุล</label>
        <input
          class="form-control custom__height"
          type="text"
          :disabled="disabled || isSubmiting"
          v-model="formTeachingDare.director_dares[0].last_name"
        />
      </div>

      <!-- <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="box5"
          >เลขบัตรประจำตัวประชาชน (ถ้ามี)</label
        >
        <input
          class="form-control custom__height"
          type="text"
          placeholder="x-xxxx-xxxxx-xx-x"
          v-mask="'#-####-#####-##-#'"
          :disabled="disabled || isSubmiting"
          v-model="formTeachingDare.director_dares[0].id_card"
        />
      </div> -->
    </div>

    <div class="d-flex flex-row justify-content-between mt-4 my-2 px-2">
      <div class="d-flex flex-row align-items-baseline">
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold ft-s-18">รายชื่อครูประจำชั้น</h4>
        <small class="text-black-50 ml-2 font-weight-bold"
          >จำนวน
          {{
            formTeachingDare &&
            formTeachingDare.teacher_dares &&
            formTeachingDare.teacher_dares.length
              ? formTeachingDare.teacher_dares.length
              : 0
          }}
          คน</small
        >
      </div>
      <b-button
        v-show="!disabled"
        size="sm"
        variant="primary"
        :disabled="isSubmiting"
        @click.prevent="addTeacher"
        >เพิ่ม</b-button
      >
    </div>

    <div
      v-if="
        formTeachingDare &&
        formTeachingDare.teacher_dares &&
        formTeachingDare.teacher_dares.length > 0
      "
    >
      <div
        v-for="(teacher, index) in formTeachingDare.teacher_dares"
        :key="`teacher__${index}`"
        class="d-flex flex-row flex-wrap"
      >
        <div class="col-lg-2 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5"
            >คำนำหน้าชื่อ</label
          >
          <!-- <input class="form-control custom__height" type="text" /> -->
          <v-select
            v-model="teacher.mPrefixId"
            :options="prefixes"
            :reduce="(prefixes) => prefixes.id"
            label="m_prefix_name"
            :loading="isFetchingPrefix"
            :disabled="disabled || isSubmiting"
          ></v-select>
        </div>
        <div class="col-lg-5 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5">ชื่อ</label>
          <input
            class="form-control custom__height"
            type="text"
            :disabled="disabled || isSubmiting"
            v-model="teacher.first_name"
          />
        </div>
        <div class="col-lg-4 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5">สกุล</label>
          <input
            class="form-control custom__height"
            type="text"
            :disabled="disabled || isSubmiting"
            v-model="teacher.last_name"
          />
        </div>
        <!-- <div class="col-lg-2 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5">ประเภทบัตร</label>
          <v-select
            v-model="teacher.mCardtypeId"
            :options="cardTypes"
            :reduce="(cardTypes) => cardTypes.id"
            label="m_cardtype_name"
            :loading="isFetchingCardType"
            :disabled="disabled || isSubmiting"
          ></v-select>
        </div> -->

        <!-- <div class="col-lg-3 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5"
            >เลขบัตรประจำตัวประชาชน (ถ้ามี)</label
          >
          <input
            class="form-control custom__height"
            type="text"
            placeholder="x-xxxx-xxxxx-xx-x"
            :disabled="disabled || isSubmiting"
            v-mask="'#-####-#####-##-#'"
            v-model="teacher.id_card"
          />
        </div> -->
        <div
          class="d-block col-lg-1 col-12 mb-2 pb-1 text-right"
          style="margin: auto"
        >
          <font-awesome-icon
            v-show="!disabled && !isSubmiting"
            icon="window-close"
            class="fa-lg cursor-pointer"
            @click.prevent="deleteTeacher(index)"
          />
        </div>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-between mt-4 my-2 px-2">
      <div class="d-flex flex-row align-items-baseline">
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold ft-s-18">รายชื่อนักเรียน</h4>
        <small class="text-black-50 ml-2 font-weight-bold"
          >จำนวน
          {{
            formTeachingDare &&
            formTeachingDare.student_dares &&
            formTeachingDare.student_dares.length
              ? formTeachingDare.student_dares.length
              : 0
          }}
          คน</small
        >
      </div>
      <b-button
        v-show="!disabled"
        size="sm"
        variant="primary"
        :disabled="disabled || isSubmiting"
        @click.prevent="addStudent"
        >เพิ่ม</b-button
      >
    </div>

    <div
      v-if="
        formTeachingDare &&
        formTeachingDare.student_dares &&
        formTeachingDare.student_dares.length > 0
      "
    >
      <div
        v-for="(student, index) in formTeachingDare.student_dares"
        :key="`student__${index}`"
        class="d-flex flex-row flex-wrap"
      >
        <div class="col-lg-2 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5"
            >คำนำหน้าชื่อ</label
          >
          <v-select
            v-model="student.mPrefixId"
            :options="prefixes"
            :reduce="(prefixes) => prefixes.id"
            label="m_prefix_name"
            :loading="isFetchingPrefix"
            :disabled="disabled || isSubmiting"
          ></v-select>
          <!-- <input class="form-control custom__height" type="text" /> -->
        </div>
        <div class="col-lg-2 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5">ชื่อ</label>
          <input
            class="form-control custom__height"
            type="text"
            :disabled="disabled || isSubmiting"
            v-model="student.first_name"
          />
        </div>
        <div class="col-lg-2 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5">สกุล</label>
          <input
            class="form-control custom__height"
            type="text"
            :disabled="disabled || isSubmiting"
            v-model="student.last_name"
          />
        </div>
        <div class="col-lg-2 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5">ประเภทบัตร</label>
          <v-select
            v-model="student.mCardtypeId"
            :options="cardTypes"
            :reduce="(cardTypes) => cardTypes.id"
            label="m_cardtype_name"
            :loading="isFetchingCardType"
            :disabled="disabled || isSubmiting"
          ></v-select>
          <!-- <input class="form-control custom__height" type="text" /> -->
        </div>
        <div class="col-lg-3 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5"
            >เลขบัตรประจำตัวประชาชน</label
          >
          <input
            v-if="isCardType(student.mCardtypeId, 'ต่างชาติ')"
            class="form-control custom__height"
            type="text"
            :disabled="disabled || isSubmiting"
            v-model="student.id_card"
          />
          <input
            v-else
            class="form-control custom__height"
            type="text"
            placeholder="x-xxxx-xxxxx-xx-x"
            :disabled="disabled || isSubmiting"
            v-mask="'#-####-#####-##-#'"
            v-model="student.id_card"
          />
        </div>
        <div
          class="d-block col-lg-1 col-12 mb-2 pb-1 text-right"
          style="margin: auto"
        >
          <font-awesome-icon
            v-show="!disabled && !isSubmiting"
            icon="window-close"
            class="fa-lg cursor-pointer"
            @click.prevent="deleteStudent(index)"
          />
        </div>
      </div>
    </div>
    <!-- <teaching-dare-form
      v-model="formTeachingDare"
      :edit-data="editData"
      :disabled="disabled || isSubmiting"
      :isClass="true"
    >
      <template #custom-body>
        
      </template>
    </teaching-dare-form> -->

    <lesson-form
      v-model="formLesson"
      :disabled="disabled || isSubmiting"
      isClass
    >
    </lesson-form>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        {{ disabled ? "ปิด" : "ยกเลิก" }}
      </b-button>
      <b-button
        v-show="!disabled"
        size="sm"
        variant="primary"
        :disabled="isSubmiting"
        @click="saveClass()"
      >
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import lessonMixin from "../../mixins/lessonMixin";
import validatorMixin from "../../mixins/validatorMixin";
import TeachingDareForm from "../form/TeachingDare";
import LessonForm from "../form/Lesson";
import FilterMaster from "../form/FilterMaster";
import FilterProvince from "../form/FilterProvince";
import vSelect from "vue-select";
import { Auth, MPrefix, MCardType, TeachingDare } from "../../models";
import { map } from "lodash";
import axios from "axios";

export default {
  mixins: [formatDateMixin, lessonMixin, validatorMixin],

  name: "CreateOrUpdateClass",

  components: {
    TeachingDareForm,
    LessonForm,
    vSelect,
    FilterMaster,
    FilterProvince,
  },

  props: {
    show: Boolean,
    classData: Object,
    editData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      filterMaster: {
        mHeadquarterId: this.editData.mHeadquarterId || null,
        mDivisionId: this.editData.mDivisionId || null,
        mStationId: this.editData.mStationId || null,
        // mSchoolId: this.editData.mSchoolId || null,
      },
      filterProvince: {
        mProvinceId: this.editData.mProvinceId || null,
        mAmphurId: this.editData.mAmphurId || null,
        mSchoolId: this.editData.mSchoolId || null,
      },
      formTeachingDare: {
        actual_student: null,
        // mStationId: "",
        // mSchoolId: "",
        // mClassId: "",
        fiscal_year: "",
        director_dares: [
          {
            first_name: "",
            last_name: "",
            id_card: "",
            phone_number: "",
            email: "",
            mPrefixId: null,
            mCardtypeId: null,
          },
        ],
        teacher_dares: [
          // {
          //   first_name: "",
          //   last_name: "",
          //   id_card: "",
          //   phone_number: "",
          //   email: "",
          //   mPrefixId: null,
          //   mCardtypeId: null,
          // },
        ],
        student_dares: [
          // {
          //   first_name: "",
          //   last_name: "",
          //   id_card: "",
          //   phone_number: "",
          //   email: "",
          //   mPrefixId: null,
          //   mCardtypeId: null,
          // },
        ],
      },
      formLesson: this.$_lessonMixin_makeLesson(),
      isFetchingPrefix: false,
      isFetchingCardType: false,
      isSubmiting: false,
    };
  },

  watch: {
    "editData.teaching_plans": "updateFormLesson",
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },

    prefixes() {
      return MPrefix.all();
    },

    cardTypes() {
      return MCardType.all();
    },

    cardTypeKeyByName() {
      return this.cardTypes.reduce((acc, cardType) => {
        this.$set(acc, cardType.id, cardType.m_cardtype_name);
        return acc;
      }, {});
    },

    authUser() {
      return Auth.user();
    },
  },

  methods: {
    isCardType(checkId, checkName) {
      return this.cardTypeKeyByName[checkId] === checkName;
    },

    chkDigitPid(p_iPID) {
      var total = 0;
      var iPID;
      var chk;
      var Validchk;
      iPID = p_iPID.replace(/-/g, "");
      Validchk = iPID.substr(12, 1);
      var j = 0;
      var pidcut;
      for (var n = 0; n < 12; n++) {
        pidcut = parseInt(iPID.substr(j, 1));
        total = total + pidcut * (13 - n);
        j++;
      }

      chk = 11 - (total % 11);

      if (chk == 10) {
        chk = 0;
      } else if (chk == 11) {
        chk = 1;
      }
      if (chk == Validchk) {
        // alert("ระบุหมายเลขประจำตัวประชาชนถูกต้อง");
        return true;
      } else {
        // alert("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
        return false;
      }
    },

    async init() {
      try {
        this.isFetchingPrefix = true;
        this.isFetchingCardType = true;
        await MPrefix.api().findAll();
        await MCardType.api().findAll();
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลคำนำหน้าหรือข้อมูลบัตรได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetchingPrefix = false;
        this.isFetchingCardType = false;
      }
    },
    updateFormLesson(teachingPlans) {
      if (teachingPlans && teachingPlans.length) {
        this.formLesson = this.$_lessonMixin_makeLesson([...teachingPlans]);
      } else {
        this.formLesson = this.$_lessonMixin_makeLesson([]);
      }
    },

    checkNullArr(array, key = "") {
      if (key == "lesson") {
        for (let index = 0; index < array.length; index++) {
          const {
            lesson_name = null,
            date = null,
            time = null,
            note = null,
          } = array[index];
          if (!lesson_name || !date || !time || !note) return true;
          return false;
        }
      } else if (key == "director") {
        for (let index = 0; index < array.length; index++) {
          const {
            first_name = null,
            last_name = null,
            mPrefixId = null,
          } = array[index];
          if (!first_name || !last_name || !mPrefixId) return true;
          return false;
        }
      } else if (key == "teacher") {
        for (let index = 0; index < array.length; index++) {
          const {
            first_name = null,
            last_name = null,
            mPrefixId = null,
          } = array[index];
          if (!first_name || !last_name || !mPrefixId) return true;
          return false;
        }
      } else {
        for (let index = 0; index < array.length; index++) {
          const {
            first_name = null,
            last_name = null,
            mPrefixId = null,
            mCardtypeId = null,
            id_card = null,
          } = array[index];
          if (
            !first_name ||
            !last_name ||
            !mPrefixId ||
            !mCardtypeId ||
            !id_card
          )
            return true;
          return false;
        }
      }
    },

    async saveClass() {
      const { mStationId, mClassId } = this.filterMaster,
        { mSchoolId } = this.filterProvince;

      const { director_dares, student_dares, teacher_dares } =
        this.formTeachingDare;

      // if (
      //   !mStationId ||
      //   !mSchoolId ||
      //   !mClassId ||
      //   !fiscal_year
      // !actual_student ||
      // director_dares.length == 0 ||
      // this.checkNullArr(director_dares, "director") ||
      // student_dares.length == 0 ||
      // this.checkNullArr(student_dares) ||
      // teacher_dares.length == 0 ||
      // this.checkNullArr(teacher_dares)
      // this.formLesson.length == 0 ||
      // this.checkNullArr(this.formLesson, "lesson")
      // ) {
      //   return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      // }

      // if (
      //   this.formLesson.length > 0 &&
      //   this.$_validatorMixin_checkNullArr(this.formLesson, [
      //     "lesson_name",
      //     "date",
      //     "time",
      //     "content",
      //   ])
      // ) {
      //   return this.$toast.error(`กรุณากรอกวันและเวลาในแผนการสอนให้เรียบร้อย`);
      // }

      if (director_dares && director_dares.length) {
        if (this.checkNullArr(director_dares, "director")) {
          return this.$toast.error(`กรุณากรอกข้อมูลผู้อำนวยการให้ครบถ้วน`);
        }

        for (let index = 0; index < director_dares.length; index++) {
          const id_card = director_dares[index].id_card;

          // if (
          //   id_card.length == 0 &&
          //   director_dares[index].mCardtypeId &&
          //   director_dares[index].mCardtypeId == 2
          // ) {
          //   director_dares[index].id_card = "";
          // } else if (!this.chkDigitPid(id_card)) {
          //   return this.$toast.error(
          //     `เลขประจำตัวประชาชนของผู้อำนวยการในลำดับที่${index +
          //       1} ไม่ถูกต้อง`
          //   );
          // }

          if (id_card.length) {
            if (!this.chkDigitPid(id_card)) {
              return this.$toast.error(
                `เลขประจำตัวประชาชนของผู้อำนวยการในลำดับที่${
                  index + 1
                } ไม่ถูกต้อง`
              );
            }
          }

          // director_dares[index].id_card = id_card.replace(/-/g, "");
        }
      }

      if (teacher_dares && teacher_dares.length) {
        if (this.checkNullArr(teacher_dares, "teacher")) {
          return this.$toast.error(`กรุณากรอกข้อมูลครูประจำชั้นให้ครบถ้วน`);
        }

        for (let index = 0; index < teacher_dares.length; index++) {
          const id_card = teacher_dares[index].id_card;

          // if (
          //   id_card.length == 0 &&
          //   teacher_dares[index].mCardtypeId &&
          //   teacher_dares[index].mCardtypeId == 2
          // ) {
          //   teacher_dares[index].id_card = "";
          // } else if (!this.chkDigitPid(id_card)) {
          //   return this.$toast.error(
          //     `เลขประจำตัวประชาชนของครูประจำชั้นในลำดับที่${index +
          //       1} ไม่ถูกต้อง`
          //   );
          // }

          if (id_card.length) {
            if (!this.chkDigitPid(id_card)) {
              return this.$toast.error(
                `เลขประจำตัวประชาชนของครูประจำชั้นในลำดับที่${
                  index + 1
                } ไม่ถูกต้อง`
              );
            }
          }

          // teacher_dares[index].id_card = id_card.replace(/-/g, "");
        }
      }

      if (student_dares && student_dares.length) {
        if (this.checkNullArr(student_dares)) {
          return this.$toast.error(`กรุณากรอกข้อมูลนักเรียนให้ครบถ้วน`);
        }

        for (let index = 0; index < student_dares.length; index++) {
          const id_card = student_dares[index].id_card;

          // if (
          //   id_card.length == 0 &&
          //   student_dares[index].mCardtypeId &&
          //   this.isCardType(student_dares[index].mCardtypeId, "ต่างชาติ")
          // ) {
          //   student_dares[index].id_card = "";
          // } else if (!this.chkDigitPid(id_card)) {
          //   return this.$toast.error(
          //     `เลขประจำตัวประชาชนของนักเรียนในลำดับที่${index + 1} ไม่ถูกต้อง`
          //   );
          // }

          if (id_card.length) {
            if (
              this.isCardType(student_dares[index].mCardtypeId, "บัตร ปชช.") &&
              !this.chkDigitPid(id_card)
            ) {
              return this.$toast.error(
                `เลขประจำตัวประชาชนของนักเรียนในลำดับที่${index + 1} ไม่ถูกต้อง`
              );
            }
          }
          // student_dares[index].id_card = id_card.replace(/-/g, "");
        }
      }

      let director_id_cards = map(director_dares, "id_card").map((id_card) =>
        id_card.replace(/-/g, "")
      );
      let teacher_id_cards = map(teacher_dares, "id_card").map((id_card) =>
        id_card.replace(/-/g, "")
      );
      let student_id_cards = map(student_dares, "id_card").map((id_card) =>
        id_card.replace(/-/g, "")
      );

      const all_id_cards = [
        ...director_id_cards,
        ...teacher_id_cards,
        ...student_id_cards,
      ];

      let isDuplicateCardIds = all_id_cards.filter(
        (item, index) => all_id_cards.indexOf(item) != index
      );

      if (isDuplicateCardIds && isDuplicateCardIds.length > 0) {
        let nullArr = [""];
        isDuplicateCardIds = isDuplicateCardIds.filter(
          (item) => !nullArr.includes(item)
        );
        if (isDuplicateCardIds && isDuplicateCardIds.length > 0) {
          return this.$toast.error(`เลขบัตรประจำตัวประชาชนห้ามซ้ำกัน`);
        }
      }

      if (this.formLesson.length > 0) {
        let checkTime;
        for (let index = 0; index < this.formLesson.length; index++) {
          const element = this.formLesson[index].time;
          if (
            this.$_validatorMixin_checkDateTime(element) === "format_incorrect"
          ) {
            this.$toast.error(`[ บทที่${index + 1}. ] ฟอร์แมตเวลาไม่ถูกต้อง`);
            checkTime = false;
            break;
          } else if (
            this.$_validatorMixin_checkDateTime(element) === "equal_time"
          ) {
            this.$toast.error(
              `[ บทที่${index + 1}. ] เวลาเริ่มต้นและเวลาสิ้นสุด ต้องไม่เท่ากัน`
            );
            checkTime = false;
            break;
          } else if (
            this.$_validatorMixin_checkDateTime(element) === "wrong_time"
          ) {
            this.$toast.error(
              `[ บทที่${index + 1}. ] เวลาเริ่มต้นต้องน้อยกว่าเวลาสิ้นสุด`
            );
            checkTime = false;
            break;
          }
          checkTime = true;
        }
        if (!checkTime) return;
      }

      // for (let index = 0; index < this.formLesson.length; index++) {
      //   this.formLesson[index].lesson_number = `${index + 1}`;
      // }

      let { formLesson = [] } = this;

      this.isSubmiting = true;

      formLesson = await this.uploadTeachingActualImages(
        this.editData.id,
        formLesson
      );

      const body = {
        mStationId,
        mSchoolId,
        mClassId,
        director_dares,
        student_dares,
        teacher_dares,
        teaching_plans: formLesson,
      };

      let promise = await TeachingDare.api().updatePlan(this.editData.id, body);

      this.isSubmiting = false;

      const { data } = promise.response;

      if (data && data.response_status == "SUCCESS") {
        this.$toast.success("อัพเดตข้อมูลการสอนเรียบร้อยแล้ว");
        this.$bvModal.hide("create-or-update-class-modal");
        this.$emit("create:success");
      } else {
        if (data.message) {
          this.$toast.error(data.message);
        } else {
          this.$toast.error("อัพเดตข้อมูลการสอนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
        }
      }
    },

    async uploadTeachingActualImages(teachingDareId, lessons) {
      const updatedLessons = await Promise.all(
        lessons.map(async (lesson) => {
          const newImages = lesson.teaching_actual_imgs.filter(
            (image) => image.raw !== undefined
          );

          const oldImages = lesson.teaching_actual_imgs.filter(
            (image) => image.id !== undefined
          );

          let teachingActualImgs = [];

          if (oldImages && oldImages.length) {
            teachingActualImgs = [...map(oldImages, "teaching_actual_img")];
          }

          let uploadedUrls = await Promise.all(
            newImages.map(async (newImage) => {
              const presignPromise = await TeachingDare.api().getUploadUrl({
                key: `teaching-dare/${teachingDareId}/lesson/${lesson.id}/teaching-actual-images/${newImage.file.name}`,
                contentType: newImage.file.type,
              });

              const { url, fullUrl } = presignPromise.response.data;

              if (url) {
                await axios.put(url, newImage.file);
              }

              return fullUrl;
            })
          );

          if (uploadedUrls && uploadedUrls.length) {
            teachingActualImgs = [...teachingActualImgs, ...uploadedUrls];
          }

          return {
            ...lesson,
            teaching_actual_imgs: teachingActualImgs,
          };
        })
      );

      return updatedLessons;
    },

    addTeacher() {
      this.formTeachingDare.teacher_dares.push({
        first_name: "",
        last_name: "",
        id_card: "",
        phone_number: "",
        email: "",
        mPrefixId: null,
        mCardtypeId: null,
      });
    },

    addStudent() {
      this.formTeachingDare.student_dares.push({
        first_name: "",
        last_name: "",
        id_card: "",
        phone_number: "",
        email: "",
        mPrefixId: null,
        mCardtypeId: null,
      });
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.filterMaster = {
        mHeadquarterId: null,
        mDivisionId: null,
        mStationId: null,
        mSchoolId: null,
      };

      this.formTeachingDare = {
        actual_student: null,
        fiscal_year: "",
        director_dares: [
          {
            first_name: "",
            last_name: "",
            id_card: "",
            phone_number: "",
            email: "",
            mPrefixId: null,
            mCardtypeId: null,
          },
        ],
        teacher_dares: [
          // {
          //   first_name: "",
          //   last_name: "",
          //   id_card: "",
          //   phone_number: "",
          //   email: "",
          //   mPrefixId: null,
          //   mCardtypeId: null,
          // },
        ],
        student_dares: [
          // {
          //   first_name: "",
          //   last_name: "",
          //   id_card: "",
          //   phone_number: "",
          //   email: "",
          //   mPrefixId: null,
          //   mCardtypeId: null,
          // },
        ],
      };
      this.formLesson = this.$_lessonMixin_makeLesson();

      this.$emit("update:editData", {});
    },
    deleteStudent(index) {
      this.formTeachingDare.student_dares.splice(index, 1);
    },
    deleteTeacher(index) {
      this.formTeachingDare.teacher_dares.splice(index, 1);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped></style>
