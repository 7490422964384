<template>
  <b-row>
    <b-col
      v-for="(image, index) in images"
      :key="`teaching-image-${index}`"
      lg="4"
      class="mb-3"
    >
      <b-card>
        <a
          v-show="removable"
          href="javascript:void(0)"
          class="btn-remove-teaching-img"
          @click="remove(index)"
        >
          <font-awesome-icon class="mx-auto" icon="window-close" />
        </a>
        <div
          v-if="image"
          class="img-fluid w-100 cursor-pointer"
          :style="getStyle(image)"
          @click="openFullScreen(image)"
        ></div>
      </b-card>
      <!-- <b-img v-if="image.raw" fluid :height="150" :src="image.raw"></b-img> -->
      <!-- <slick ref="slick" :options="slickOptions">
        <div
          v-for="(image, index) in images"
          :key="`teaching-image-${index}`"
          class="card-body"
        >
          <div class="slider-item">
            <div
              v-if="image.raw"
              class="img-fluid w-100 h-100"
              :style="getStyle(image)"
            ></div>
          </div>
        </div>
      </slick> -->
    </b-col>

    <slot></slot>

    <b-modal
      id="teaching-img-fullscreen-modal"
      v-model="showFullScreen"
      centered
      scrollable
      size="xl"
      header-class="modal__header"
      footer-class="modal__footer"
      body-class="modal__body"
      hide-footer
      @hidden="onHidden"
    >
      <b-card>
        <img
          v-if="fullScreenImage"
          class="d-block img-fluid w-100"
          :src="
            fullScreenImage.id
              ? fullScreenImage.teaching_actual_img
              : fullScreenImage.raw
          "
        />
      </b-card>
    </b-modal>
  </b-row>
</template>

<script>
// import Slick from "vue-slick";

export default {
  props: {
    images: Array,
    removable: {
      type: Boolean,
      default: true,
    },
  },

  model: {
    prop: "images",
    event: "change",
  },

  components: {
    // Slick,
  },

  data() {
    return {
      showFullScreen: false,
      fullScreenImage: null,
      fullScreenImageWidth: null,
      fullScreenImageHeight: null,
      //   slickOptions: {
      //     infinite: true,
      //     slidesToShow: 2,
      //     slidesToScroll: 1,
      //     dots: true,
      //     arrows: true,
      //     responsive: [
      //       {
      //         breakpoint: 1024,
      //         settings: {
      //           slidesToShow: 2,
      //         },
      //       },
      //     ],
      //   },
    };
  },

  watch: {
    // images: "reInit",
  },

  methods: {
    // reInit() {
    //   this.$nextTick(() => {
    //     this.$refs.slick.reSlick();
    //   });
    // },
    openFullScreen(image) {
      // const self = this;
      // const tempImage = new Image();

      // tempImage.onload = function () {
      //   self.fullScreenImageWidth = this.width;
      //   self.fullScreenImageHeight = this.height;
      //   self.showFullScreen = true;
      // };

      // tempImage.src = image.teaching_actual_img
      //   ? image.teaching_actual_img
      //   : image.raw;

      this.fullScreenImage = image;
      this.showFullScreen = true;
    },

    onHidden() {
      this.fullScreenImage = null;
      // this.fullScreenImageWidth = null;
      // this.fullScreenImageHeight = null;
    },

    remove(index) {
      const removedImages = [...this.images];

      removedImages.splice(index, 1);

      this.$emit("change", removedImages);
    },

    getStyle(image) {
      return {
        height: "150px",
        backgroundImage: `url('${
          image.id ? image.teaching_actual_img : image.raw
        }')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
      };
    },

    getFullScreenStyle(image) {
      return {
        width: `${this.fullScreenImageWidth}px`,
        height: `${this.fullScreenImageHeight}px`,
        backgroundImage: `url('${
          image.id ? image.teaching_actual_img : image.raw
        }')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-remove-teaching-img {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
