<template>
  <b-modal
    v-model="$show"
    id="delete-modal"
    centered
    hide-header
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <h3 class="font-weight-bold">คุณต้องการลบสิ่งนี้</h3>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button size="sm" variant="primary" @click="deleteItem">
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>ยืนยัน</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { TeachingDare } from "../../models";

export default {
  props: {
    show: Boolean,
    classData: Object,
    deleteData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isSubmiting: false,
    };
  },

  model: {
    prop: "show",
    event: "update",
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

  methods: {
    async deleteItem() {
      this.isSubmiting = true;
      try {
        let promise = await TeachingDare.api().deletePlan(this.deleteData.id);
        const { data } = promise.response;
        if (data && data.response_status == "SUCCESS") {
          this.$toast.success("ลบข้อมูลสำเร็จ");
          this.$bvModal.hide("delete-modal");
          this.$emit("delete:success");
        } else {
          this.$toast.error("ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
        }
      } catch (error) {
        this.$toast.error("ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isSubmiting = false;
      }
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.$emit("update:deleteData", {});
    },
  },
};
</script>