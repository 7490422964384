<template>
  <page-content>
    <page-title heading="ข้อมูลการสอน">
      <!-- <template v-slot:right>
        <b-button variant="danger" @click="onCreate" disabled
          >แบบประเมิน</b-button
        >
      </template> -->
    </page-title>

    <filter-master
      v-if="authUser.isAdminType"
      v-model="filterMaster"
      :m-headquarter-id="
        !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
      "
      :m-division-id="
        !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
      "
      :m-station-id="
        !authUser.canAccessAllStations ? authUser.mStationId : null
      "
      :disabledInput="{
        headQuarter:
          !authUser.canAccessAllHeadQuarters &&
          authUser.mHeadquarterId !== null,
        division:
          !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
        station: !authUser.canAccessAllStations && authUser.mStationId !== null,
      }"
      col-headquarter="4"
      col-division="4"
      col-station="4"
      :hidden-input="{ school: true, schoolClass: true }"
    >
    </filter-master>

    <div class="d-flex flex-row flex-wrap mt-2">
      <b-col cols="6" class="px-2">
        <b-form-group class="mb-0">
          <b-input-group class="input-group-seamless">
            <b-form-input
              :placeholder="
                authUser.isAdminType
                  ? 'ค้นหารายชื่อครูหรือรายชื่อโรงเรียน'
                  : 'ค้นหารายชื่อโรงเรียน'
              "
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="2" class="px-2">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col>
    </div>

    <teaching-dare-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="params"
      action-button-text="บันทึกการสอน"
      @edit="onEdit"
      @delete="onDelete"
      @seeQrCode="onSeeQrCode"
      :survey="true"
    />

    <create-or-update-class
      :edit-data.sync="editData"
      @create:success="onCreateSuccess"
      v-model="showModalClass"
    ></create-or-update-class>

    <base-delete
      v-model="showModalDelete"
      @delete:success="onDeleteSuccess"
      :delete-data.sync="deleteData"
    ></base-delete>

    <!-- <modal-qr-code-survey-teaching-dare
      v-model="showModalQrCode"
      :item="item"
    ></modal-qr-code-survey-teaching-dare> -->
  </page-content>
</template>

<script>
import { sortBy } from "lodash";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateClass from "../../components/modal/CreateOrUpdateClass";
import BaseDelete from "../../components/modal/BaseDelete";
import TeachingDareTable from "../../components/table/TeachingDare";
import FilterMaster from "../../components/form/FilterMaster";
// import ModalQrCodeSurveyTeachingDare from "../../components/modal/QrCodeSurveyTeachingDare";
import {
  Auth,
  DirectorDare,
  StudentDare,
  TeacherDare,
  TeachingPlan,
} from "../../models";

export default {
  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateClass,
    TeachingDareTable,
    BaseDelete,
    FilterMaster,
    // ModalQrCodeSurveyTeachingDare,
  },

  data() {
    return {
      filterMaster: {},
      keyword: "",
      formfilter: {},
      editData: {},
      deleteData: {},
      showModalClass: false,
      showModalDelete: false,
      showModalQrCode: false,
      item: {},
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    fields() {
      let fields = [{ key: "station", label: "สถานี", sortable: true }];

      if (this.authUser.isAdminType) {
        fields.push({
          key: "user_name",
          label: "ชื่อ-นามสกุล",
          sortable: true,
        });
      }

      fields = [
        ...fields,
        { key: "school", label: "โรงเรียน", sortable: true },
        // { key: "m_school.m_amphur.m_amphur_name", label: "อำเภอ" },
        // { key: "m_school.m_province.m_province_name", label: "จังหวัด" },
        { key: "yearTerm", label: "ปีการศึกษา", sortable: true },
        { key: "classRoom", label: "ห้องเรียน", sortable: true },
        {
          key: "plan_student",
          label: "นร.ตามแผน",
          class: "text-center",
          sortable: true,
        },
        {
          key: "actual_student",
          label: "นร.จริง",
          class: "text-center",
          sortable: true,
        },
        {
          key: "created_at",
          label: "วันที่สร้าง",
        },
      ];

      // const fields = [
      //   // { key: "order", label: "ลำดับ" },
      //   { key: "m_station.m_station_name", label: "สถานี" },
      //   { key: "m_school.m_school_name", label: "โรงเรียน" },
      //   { key: "m_school.m_amphur.m_amphur_name", label: "อำเภอ" },
      //   { key: "m_school.m_province.m_province_name", label: "จังหวัด" },
      //   { key: "classRoom", label: "ห้องเรียน" },
      //   { key: "yearTerm", label: "ปีการศึกษา" },
      //   { key: "plan_student", label: "นร.ตามแผน", class: "text-center" },
      //   { key: "actual_student", label: "นร.จริง", class: "text-center" },
      //   {
      //     key: "created_at",
      //     label: "วันที่สร้าง",
      //   },
      // ];

      // if (this.authUser.isAdminType) {
      //   fields.push({ key: "user_name", label: "สร้างโดย" });
      // }

      fields.push({ key: "survey", label: "แบบประเมิน" });
      return fields;
    },

    params() {
      const { filterMaster: params, formfilter } = this;

      if (!this.authUser.isAdminType) {
        this.$set(params, "userProfileId", this.authUser.id);
      }

      return { ...params, ...formfilter };
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    onCreate() {
      this.showModalClass = true;
    },

    async onEdit(item) {
      this.editData = { ...item };

      if (item.m_station) {
        this.$set(this.editData, "mDivisionId", item.m_station.mDivisionId);

        if (item.m_station.m_division) {
          this.$set(
            this.editData,
            "mHeadquarterId",
            item.m_station.m_division.mHeadquarterId
          );
        }
      }

      if (item.m_school) {
        this.$set(this.editData, "mProvinceId", item.m_school.mProvinceId);
        this.$set(this.editData, "mAmphurId", item.m_school.mAmphurId);
      }

      const [
        teachingPlanPromise,
        directorDarePromise,
        studentDarePromise,
        teacherDarePromise,
      ] = await Promise.all([
        TeachingPlan.api().findAll({ teachingDareId: item.id }),
        DirectorDare.api().findAll({ teachingDareId: item.id }),
        StudentDare.api().findAll({ teachingDareId: item.id }),
        TeacherDare.api().findAll({ teachingDareId: item.id }),
      ]);

      const { data: teachingPlanData = [] } = teachingPlanPromise.response.data;

      if (teachingPlanData.length) {
        this.$set(
          this.editData,
          "teaching_plans",
          sortBy(teachingPlanData, "createdAt").map(
            ({
              lesson_number,
              lesson_name,
              date,
              time,
              content,
              note,
              teaching_actual_imgs = [],
            }) => ({
              lesson_number,
              lesson_name,
              date,
              time,
              content,
              note,
              teaching_actual_imgs,
            })
          )
        );
      }

      const { data: directorDareData = [] } = directorDarePromise.response.data;

      if (directorDareData.length) {
        this.$set(
          this.editData,
          "director_dares",
          sortBy(directorDareData, "createdAt").map(
            ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            }) => ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            })
          )
        );
      }

      const { data: studentDareData = [] } = studentDarePromise.response.data;

      if (studentDareData.length) {
        this.$set(
          this.editData,
          "student_dares",
          sortBy(studentDareData, "createdAt").map(
            ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            }) => ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            })
          )
        );
      }

      const { data: teacherDareData = [] } = teacherDarePromise.response.data;

      if (teacherDareData.length) {
        this.$set(
          this.editData,
          "teacher_dares",
          sortBy(teacherDareData, "createdAt").map(
            ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            }) => ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            })
          )
        );
      }

      this.showModalClass = true;
    },

    onSeeQrCode(item) {
      this.item = { ...item };
      this.showModalQrCode = true;
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
