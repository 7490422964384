import { keyBy } from "lodash";

const LESSONS = [
    {
        lesson_number: "1",
        lesson_name: "วัตถุประสงค์และภาพรวมของหลักสูตร D.A.R.E.",
        content: "- แนะนำภาพรวมโครงการ D.A.R.E.\n- แนะนำโครงการ D.A.R.E. ประเทศไทย  \n- องค์ประกอบของหลักสูตร D.A.R.E. \n- ขั้นตอนรูปแบบการตัดสินใจแบบ D.A.R.E.  \n-  การจัดระเบียบในห้องเรียน\n-  การทำความรู้จัก"
    },
    {
        lesson_number: "2",
        lesson_name: "บุหรี่กับนักเรียน",
        content: "- เป็นการเปลี่ยนความเชื่อของเด็กนักเรียนให้รู้ความจริงว่า เด็กนักเรียนส่วนใหญ่ไม่สูบบุหรี่ เพื่อช่วยเด็กนักเรียนให้สามารถพัฒนาความรู้พื้นฐานเกี่ยวกับบุหรี่และผลร้ายของมัน เมื่อผ่านการเรียนบทนี้แล้ว เด็กนักเรียนสามารถระบุผลร้ายของบุหรี่ที่มีต่อร่างกาย และสามารถใช้รูปแบบการตัดสินใจแบบ D.A.R.E.เมื่อถูกชักชวนให้สูบบุหรี่ได้"
    },
    {
        lesson_number: "3",
        lesson_name: "ม่านควัน",
        content: "- ทบทวน และแนะนำบทเรียน\n- เพื่อให้เด็กนักเรียนเรียนรู้ข้อเท็จจริงเกี่ยวกับผลร้ายของกัญชา ซึ่งเป็นการสูบอีกชนิดหนึ่งรวมทั้งความแตกต่างของพิษภัยในบุหรี่และกัญชาที่ส่งผลร้ายต่อร่างกายอีกด้วย"
    },
    {
        lesson_number: "4",
        lesson_name: "เหล้ากับนักเรียน",
        content: "- เพื่อให้เด็กนักเรียนได้เรียนรู้ข้อเท็จจริงและผลกระทบของการดื่มเหล้าที่มีต่อสมองและร่างกาย สามารถใช้รูปแบบการตัดสินใจแบบ D.A.R.E. เมื่อต้องเผชิญกับสถานการณ์การถูกชวนให้ดื่มเหล้า"
    },
    {
        lesson_number: "5",
        lesson_name: "ความจริง",
        content: "- ทบทวนบทเรียนที่ผ่านมา\n- เด็กนักเรียนได้เรียนรู้ความจริงและผลกระทบที่เกิดจากโฆษณาเหล้า นำเสนอเนื้อหาเกี่ยวกับสารระเหย เหล้า บุหรี่ และยาเสพติดอื่น ๆ – - - บทเรียนนี้เด็กนักเรียนจะได้ทำกิจกรรมแข่งขันผลัดกันตอบ โดยระบุผลร้ายจากยาเสพติดประเภทต่าง ๆ และระบุกิจกรรมสร้างสรรค์ที่ไม่ยุ่งเกี่ยวกับยาเสพติด"
    },
    {
        lesson_number: "6",
        lesson_name: "รากฐานของมิตรภาพ",
        content: "- การแนะนำบทเรียน\n- นักเรียนได้เรียนรู้คุณสมบัติของเพื่อนที่ดีและเพื่อนที่ไม่ดี ความหมายของแรงกดดันจากเพื่อนและการแสดงบทบาทสมมุติ โดยใช้รูปแบบการตัดสินใจแบบ D.A.R.E. เพื่อโต้ตอบแรงกดดันจากเพื่อนประเภทต่าง ๆ"
    },
    {
        lesson_number: "7",
        lesson_name: "การรวมทุกสิ่งเข้าด้วยกัน",
        content: "-  แนะนำบทเรียน\n-  การฝึกทักษะการปฏิเสธยาเสพติด หลีกเลี่ยงความรุนแรง / สถานการณ์เสี่ยง เพื่อดูแลรับผิดชอบชีวิตตนเอง โดยใช้รูปแบบการโต้ตอบแบบมั่นใจ และแสดงความสามารถในการประยุกต์ใช้ขั้นตอนการตัดสินใจได้"
    },
    {
        lesson_number: "8",
        lesson_name: "การกระทำของแต่ละคน",
        content: "- แนะนำบทเรียน\n- การอธิบายถึงแรงกดดันจากภายในที่มีอิทธิพลต่อการตัดสินใจเกี่ยวกับการใช้ยาเสพติด ทบทวนบทเรียนหลักสูตร D.A.R.E. เพื่อนำข้อมูลมาเขียนเรียงความ D.A.R.E."
    },
    {
        lesson_number: "9",
        lesson_name: "ฝึก ฝึก และฝึก",
        content: "- แนะนำบทเรียน\n- เป็นการประยุกต์ใช้รูปแบบการตัดสินใจแบบ D.A.R.E. วิธีการดูแลรับผิดชอบตนเอง และการโต้ตอบอย่างมั่นใจในสถานการณ์เสี่ยงสำหรับการใช้ชีวิตประจำวันของเด็กนักเรียน"
    },
    {
        lesson_number: "10",
        lesson_name: "การป้องกันอาชญากรรมด้วยตนเอง",
        content: "- แนะนำบทเรียน\n- นักเรียนสามารถหลีกเลี่ยงภัยจากการก่ออาชญากรรม สาเหตุของการเกิดอาชญากรรม รู้วิธีหลีกเลี่ยงอาชญากรรม สถานการณ์การก่ออาชญากรรมในปัจจุบัน"
    },
    {
        lesson_number: "11",
        lesson_name: "ความรู้เกี่ยวกับการจราจร",
        content: "- แนะนำบทเรียน\n- การใช้รถใช้ถนนอย่างปลอดภัยใน \n- รู้กฎหมายจราจรที่เกี่ยวข้อง รู้เรื่องเกี่ยวกับเครื่องหมายจราจรที่สำคัญ รู้มาตรการการขับขี่อย่างปลอดภัย การแก้ปัญหาเบื้องต้นเมื่อประสบอุบัติเหตุ"
    },
    {
        lesson_number: "12",
        lesson_name: "โรงพักของเรา",
        content: "- เด็กนักเรียนได้เรียนรู้ระบบการทำงานของตำรวจ วิธีติดต่องานกับเจ้าหน้าที่ตำรวจ รวมถึงระบบงานยุติธรรมเบื้องต้น"
    },
    {
        lesson_number: "13",
        lesson_name: "กิจกรรมพิเศษ",
        content: "- การจัดกิจกรรมวันปิดโครงการ D.A.R.E. สร้างความภาคภูมิใจให้กับนักเรียน D.A.R.E. โดยเชิญผู้ที่เกี่ยวข้องกับโครงการฯ มาเป็นเกียรติ เช่น ผู้บริหารโรงเรียน คุณครูประจำชั้น ผู้ปกครอง ผู้บังคับบัญชาของครูตำรวจ D.A.R.E. และหน่วยงานที่สนับสนุน โดยมีการอ่านคำปฏิญาณของนักเรียน D.A.R.E. การแสดงของนักเรียน และการมอบประกาศนียบัตรให้แก่นักเรียน D.A.R.E."
    },
];

const lessonMixin = {
    methods: {
        $_lessonMixin_makeLesson(data = []) {
            const lessons = [...LESSONS];
            const groupedData = keyBy(data, "lesson_number");

            return lessons.map(lessson => {
                const lessonData = {
                    date: "",
                    time: "",
                    note: "",
                    teaching_actual_imgs: []
                };

                const existedLesson = groupedData[lessson.lesson_number];

                if (existedLesson) {
                    this.$set(lessonData, "date", existedLesson.date);
                    this.$set(lessonData, "time", existedLesson.time);
                    this.$set(lessonData, "note", existedLesson.note);
                    this.$set(lessonData, "teaching_actual_imgs", existedLesson.teaching_actual_imgs);
                }

                return {
                    ...lessonData,
                    ...lessson
                };
            });
        }
    }
};

export default lessonMixin;